import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';

import { PatientService } from '@app/patient/patient.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HearingScreeningService } from '../hearing-screening/hearing-screening.service';
import { PrintService } from '@app/shared/print/print.service';
import { UserService } from '@app/user/user.service';
import html2canvas from 'html2canvas';
import { ScreeningResultsService } from './screening-results.service';
import { AlertService } from '@app/shared/alert/alert.service';
import { environment } from '@environments/environment';
import { StatusService } from '../hearing-screening/status.service';

@Component({
  template: ''
})
export class ScreeningResultsComponent implements OnInit {

  id: string;

  constructor(public dialog: MatDialog,
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ScreeningResultsComponentDialog, {
      data: { id: this.id }
    });
    dialogRef.afterClosed().subscribe(result => {
        this.location.back();
    });
  }

}

@Component({
  templateUrl: './screening-results.component.html',
  styleUrls: ['./screening-results.component.scss']}
)
export class ScreeningResultsComponentDialog implements OnInit {

  elementType = 'url';
  correctionLevel = 'H';
  value = "https://demo-ennhsr.nhsrc.ph/#/validate_pn/";

  emailResults: boolean;
  
  typeOfScreening: string;
  methodOfScreening: string;

  id: string;
  patient$: Observable<any>;
  screening$: Observable<any>;
  sealNo: number;

  fileToUpload: File = null;

  form: FormGroup;
  isDesktop;

  constructor(        
    private patientService: PatientService, 
    private hearingScreeningService: HearingScreeningService,
    public dialogRef: MatDialogRef<ScreeningResultsComponentDialog>,   
    private printService: PrintService,
    private userService: UserService,
    private screeningResultsService: ScreeningResultsService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private statusService: StatusService,
    @Inject(MAT_DIALOG_DATA) public data: {
        formBuilder: FormBuilder,
        id: string
    }
  ) { 
    this.id = data.id;
  }

  ngOnInit() {
    this.isDesktop = environment.isDesktop;
    this.emailResults = false;
    this.patient$ = this.patientService.recordSubject
    .pipe(
      first(),
      map(x => {
        this.value += x['dataModel']['patientNumber'];
        return x['dataModel']
      })
    );

    this.form = this.formBuilder.group({
      emailAdd: [''],
      pdf: ['']
    });
    
    var screeningRecords = this.hearingScreeningService.recordsValue['results']
    this.screening$ =  this.hearingScreeningService.records
    this.sealNo = this.statusService.recordsSubject.getValue()["dataModel"]["serialNumber"];
  }

  validatePrivilege() {
    var user = this.userService.userValue
    return user['isAdmin'] || user['facility']['roles'].includes('Category A') || user['facility']['roles'].includes('Category B') || user['facility']['roles'].includes('Category C') || user['facility']['roles'].includes('Category D');
  }

  sendEmail() {
    this.screeningResultsService.add(this.form.value)
      .pipe(first())
      .subscribe({
          next: () => {
              this.alertService.success('Screening Results sent to E-mail', { keepAfterRouteChange: true });
          },
          error: error => {
              this.alertService.error(error);
              //this.loading = false;
          }
      });
  }

  exportAsPDF(divId, files: FileList) {
      
    let data = document.getElementById('email');  

    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')  
      this.form.get("pdf").setValue(contentDataURL);
    });
  }

  savePDF(files: FileList) {
    this.fileToUpload = files.item(0);
  }

}
